<template>
  <div class="content">
    <div class="toptitle">
      <span class="size36">企业责任</span>
      <p>Responsibility</p>
    </div>
    <div class="fix" @click="btn()">关于我们 &ensp;> &ensp;社会责任</div>
    <div class="title1">人车防护 力保司乘安全</div>
    <div class="box"></div>
    <div class="title2">
      新冠疫情爆发后，公司为所有运营网约车、出租车、租赁车辆提供免费消毒服务和医用口罩，其中消毒服务对全市所有社会车辆同步免费提供，全市目前共设置3个消毒站点（包河区南二环和徽州大道交叉口西南角和瑞出租车院内、长丰县水湖镇长寿路177号长丰县百帮就业园、肥西县繁华大道与万佛山路交口合肥和瑞出租车有限公司），目前累计消毒车辆近5000辆。公司对所有营运车辆驾驶员体温、近期接触人员和乘客情况进行每日登记，使疫情防控工作有迹可循、有记录可查。
    </div>
    <img src="../../../static/images/about/3.png" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    btn() {
      this.$router.push({ name: "pcsocial" });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  font-weight: 400;
  color: #2d2d39;
  font-size: 14px;
  .toptitle {
    box-sizing: border-box;
    padding: 6% 0 0 4%;
    .size36 {
      font-size: 36px;
    }
    p {
      margin-left: 1px;
      font-size: 16px;
    }
  }
  .fix {
    cursor: pointer;
    width: 100%;
    height: 43px;
    line-height: 43px;
    margin-top: 10px;
    padding-left: 4%;
    box-sizing: border-box;
    background-color: #f2f2f2;
  }
  .title1 {
    width: 61.8%;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
    margin: 45px 0 0 4%;
  }
  .box {
    width: 61.8%;
    height: 1px;
    background-color: #e9e9e9;
    margin: 15px 0 0 4%;

  }
  .title2 {
    line-height: 25px;
    width: 61.8%;
    margin: 35px 0 0 4%;
  }
  img {
    width: 61.8%;
    margin: 30px 0 0 4%;
  }
}
</style>